<template>
  <div class="shipping__create-page">
    <v-row>
      <v-col cols="12">
        <redirect class="" title="Shipping" route="website.settings.shipping" />
      </v-col>
      <v-col cols="12" class="page-header">
        <Notification type="error" :messages="error" v-if="error.length > 0" />
        <h2 class="page-title">Add shipping zone</h2>
      </v-col>
      <v-col cols="12" v-if="isLoading">
        <SkeletonLoaders type="card-heading, list-item-three-line" />
      </v-col>
      <v-col v-else cols="12">
        <v-row no-gutters>
          <v-col cols="12" class="elevation-form px-5 py-5">
            <v-text-field
              v-model="shippingZone.name"
              placeholder="e.g. North America, Euro"
              @input="$v.shippingZone.name.$touch"
              :error-messages="
                $v.shippingZone.name.$dirty && !$v.shippingZone.name.required ? $t('Name is required') : ''
              "
            ></v-text-field>
            <small>Customers won't see this.</small>
          </v-col>
          <v-col cols="12" class="elevation-form mt-5 px-5 py-5">
            <Countries />
          </v-col>
          <v-col cols="12" class="elevation-form mt-5 px-5 py-5">
            <PriceBasedRate />
          </v-col>
          <v-col cols="12" class="elevation-form mt-5 px-5 py-5">
            <WeightBaseRate />
          </v-col>
          <v-col cols="12" class="elevation-form mt-5 px-5 py-5">
            <ItemBaseRate />
          </v-col>
          <v-col cols="12" class="elevation-form mt-5 px-5 py-5">
            <PreferredRules />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mt-5 d-flex align-center px-5 py-5">
        <v-btn
          :loading="isLoadingAction"
          :disabled="isLoadingAction"
          @click="deleteShippingZone(shippingZone.id)"
          color="error"
        >
          Delete
        </v-btn>
        <v-btn :loading="isLoadingAction" :disabled="isLoadingAction" class="ml-auto" @click="save" color="primary">
          Save
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Countries from './components/Countries';
import PriceBasedRate from './components/PriceBasedRate';
import WeightBaseRate from './components/WeightBaseRate';
import ItemBaseRate from './components/ItemBaseRate';
import PreferredRules from './components/PreferredRules';
import { required, minLength, maxLength, between, email } from 'vuelidate/lib/validators';
import { shippingService } from '@/apis/shipping';

export default {
  props: ['id'],
  components: {
    Countries,
    PriceBasedRate,
    WeightBaseRate,
    ItemBaseRate,
    PreferredRules,
  },
  validations: {
    shippingZone: {
      name: {
        required,
      },
    },
  },
  data() {
    return {
      shippingZone: {
        countries: [],
        itemBasedShippingRate: [],
        name: '',
        preferredRules: 'lowest',
        priceBasedShippingRate: [],
        type: 'rest_of_world',
        weightBasedShippingRate: [],
      },
      isLoading: false,
      isLoadingAction: false,
      error: [],
    };
  },
  created() {
    this.getShipping();
  },
  methods: {
    async getShipping() {
      this.isLoading = true;
      try {
        let shippingData = await shippingService.get(this.id);
        this.shippingZone = shippingData.data;
        if (this.shippingZone) {
          if (!this.shippingZone.countries) {
            this.shippingZone.countries = [];
          }
        }
        this.isLoading = false;
      } catch (error) {
        this.error = error;
        this.isLoading = false;
      }
    },
    async deleteShippingZone(id) {
      this.isLoadingAction = true;
      try {
        let shippingData = await shippingService.deleteById(this.id);
        this.isLoadingAction = false;

        this.$router.push({ name: 'website.settings.shipping' });
      } catch (error) {
        this.isLoadingAction = false;
        this.error = error;
      }
    },
    async save() {
      this.$v.$touch();
      this.isLoadingAction = true;
      this.error = [];
      if (
        this.shippingZone.itemBasedShippingRate.length == 0 &&
        this.shippingZone.priceBasedShippingRate.length == 0 &&
        this.shippingZone.weightBasedShippingRate.length == 0
      ) {
        this.error.push(`
          Your shipping zone doesn’t have any rates. You need to add at least one rate to accept orders from customers in this shipping zone.
        `);
      }
      if (!this.$v.$invalid && this.error.length == 0) {
        try {
          let shippingData = await shippingService.update(this.shippingZone);
          this.isLoadingAction = false;
        } catch (error) {
          this.isLoadingAction = false;
          this.error = error;
        }
      } else {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss">
.shipping__create-page {
  .list-countries {
    max-height: 400px;
    overflow-y: auto;
  }
}
</style>
